import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withTranslation } from 'react-i18next';

import LocationCityRoundedIcon from '@material-ui/icons/LocationCityRounded';
import IconComment from '@material-ui/icons/ChatBubbleOutlineRounded';
import IconDateRange from '@material-ui/icons/DateRange';
import IconPerson from '@material-ui/icons/Person';
import IconMessage from '@material-ui/icons/Message';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import GridMui from '@material-ui/core/Grid';
import _ from 'lodash';

import {
  Grid,
  PagingPanel,
  Table,
  TableRowDetail,
  TableHeaderRow,
  TableColumnVisibility,
} from '@devexpress/dx-react-grid-material-ui';

import {
  IntegratedSorting,
  RowDetailState,
  PagingState,
  SortingState,
  CustomPaging,
} from '@devexpress/dx-react-grid';

import IconCheck from '@material-ui/icons/Check';
import QuestionMarkIcon from '@mui/icons-material/QuestionMark';
import CloseIcon from '@material-ui/icons/Close';
import _get from 'lodash/get';
import Chip from '@material-ui/core/Chip';

import { green, grey, orange, red } from '@material-ui/core/colors';
import Button from '@material-ui/core/Button';
import { Link, withRouter } from 'react-router-dom';
import _cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import LayoutStyles from '../../Layout/Styles/LayoutStyles';
import WelloAvatar from '../../Shared/WelloAvatar';
import { ASK_FOR, isSharedLead } from '../../../Services/LeadHelper';
import { getExpertiseStyle } from '../../../Themes/Expertises';
import { checkHostConstraint, HOSTS_NAMES } from '../../../Routing/HostConstraint';
import { getRoleInOrga } from '../../../Acl/Controls';
import { CheckRoleRule } from '../../../Acl/Rules';

type Props = {
  leads: Array,
  classes: Object,
  organisation: Object,
  gridMode: String,
  currentPage: Number,
  currentUser: Object,
  totalLeads: Number,
  onUpdatePage: Function,
  onUpdateLead: Function,
  canArchive: Function,
  generateShowLeadLink: Function,
  setSort: Function,
  t: Function,
};

const styles = theme => ({
  layout: {
    ...LayoutStyles.fullWidthLayout,
    paddingTop: '10px',
  },
  appBarContainer: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
  },
  grow: {
    flexGrow: 1,
  },
  chip: {
    marginLeft: '0px',
    marginRight: '3px',
    marginBottom: '3px',
    height: '20px',
    color: 'white',
    maxWidth: '-webkit-fill-available',
  },
  checkIcon: {
    color: green[600],
  },
  questionIcon: {
    color: orange[600],
  },
  crossIcon: {
    color: red[600],
  },
  infoLine: {
    display: 'flex',
    paddingBottom: theme.spacing(1),
    alignItems: 'center',
  },
  infoText: {
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1),
  },
  badge: {
    width: 22,
    height: 22,
    fontSize: '0.75rem',
  },
  detail: {
    margin: theme.spacing(-2),
    padding: theme.spacing(2),
    backgroundColor: 'white',
  },
});

const getHiddenColumns = gridMode => {
  // if in organisation page : do not show organisation name / if in holding page : do not show city because not relevant and we need space
  let columns = [];
  switch (gridMode) {
    case 'Holding':
      columns = ['ville'];
      break;
    case 'Organisation':
      columns = ['organisation'];
      break;
    case 'Family':
      columns = ['ville', 'organisation'];
      break;
    default:
      columns = [];
  }

  if (window.innerWidth <= 600) {
    columns.push('type');
    columns.push('commentaire');
  }

  return columns;
};

class GridLeads extends React.Component<Props> {
  constructor(props) {
    super(props);
    const { gridMode, currentPage, t } = this.props;
    this.state = {
      sorting: [
        {
          columnName: checkHostConstraint(HOSTS_NAMES.mormal) ? 'departure_date' : 'created_at',
          direction: 'desc',
        },
      ],
      columns: [
        {
          name: 'organisation',
          title: 'Équipe',
          getCellValue: row => (row.organisation ? row.organisation.name : undefined),
        },
        {
          name: 'demandeur',
          title: t('APP.LEADS.COLUMN_APPLICANT'),
          getCellValue: row => (row.organisation ? row.full_name : undefined),
        },
        {
          name: 'ville',
          title: (
            <LocationCityRoundedIcon
              style={{ alignItems: 'center', display: 'flex', verticalAlign: 'bottom' }}
            />
          ),
          getCellValue: row => this.getCity(row),
        },
        {
          name: 'type',
          title: t('APP.LEADS.COLUMN_TYPE'),
        },
        {
          name: 'commentaire',
          title: (
            <IconComment
              style={{ verticalAlign: 'bottom', alignItems: 'center', display: 'flex' }}
            />
          ),
          getCellValue: row => row.posts_count,
        },
        {
          name: 'actions',
          title: t('APP.LEADS.COLUMN_STATUS'),
          getCellValue: row => (row.responsible ? row.responsible : undefined),
        },
        {
          name: 'datedemande',
          title: t('APP.LEADS.COLUMN_CREATED_AT'),
          getCellValue: row => (row.created_at ? row.created_at : undefined),
        },
      ],
      pageSize: 30,
      currentPage,
      expandedRowIds: [],
      hiddenColumnNames: getHiddenColumns(gridMode),
    };

    if (checkHostConstraint(HOSTS_NAMES.mormal)) {
      this.state.columns.push({
        name: 'departure_date',
        title: t('APP.LEADS.COLUMN_DEPARTURE_DATE'),
        getCellValue: row =>
          row.meta && row.meta.departure_date ? row.meta.departure_date : undefined,
      });
    }

    this.expandRow = rowId => {
      const { expandedRowIds } = this.state;

      const index = expandedRowIds.findIndex(id => rowId === id);
      const nextExpandedRowIds = [...expandedRowIds];
      // debugger;
      if (index > -1) {
        nextExpandedRowIds.splice(index, 1);
      } else {
        nextExpandedRowIds.push(rowId);
      }
      this.setState({ expandedRowIds: nextExpandedRowIds });
    };
  }

  componentDidUpdate(prevProps, prevState) {
    const { onUpdatePage } = this.props;
    const { currentPage } = this.state;

    if (prevProps.currentPage !== this.props.currentPage) {
      this.setCurrentPage(this.props.currentPage);
    }

    if (currentPage !== prevState.currentPage) {
      onUpdatePage(currentPage);
    }
  }

  setCurrentPage = currentPage => {
    this.setState({
      currentPage,
    });
  };

  setExpandedRowIds = expandedRowIds => {
    this.setState({
      expandedRowIds,
    });
  };

  handleToggleArchiveMenuItemClick = (row, event) => {
    event.preventDefault();
    const { onUpdateLead } = this.props;
    const updatingRow = _cloneDeep(row);
    updatingRow.archived = !updatingRow.archived;
    onUpdateLead(row.organisation, updatingRow, 'remove');
  };

  handleProposeMyselfMenuItemClick = (row, event) => {
    event.preventDefault();
    const { onUpdateLead, currentUser } = this.props;
    const updatingRow = _cloneDeep(row);
    updatingRow.responsible_id = currentUser.id;
    updatingRow.validated = true; // always validate directly
    onUpdateLead(row.organisation, updatingRow);
  };

  getCity = row => {
    const { helper } = row;
    const { formatted_address: rowAdresse, locality } = row;
    const helperAddress = _.get(helper, 'formatted_adresse');
    const helperLocality = _.get(helper, 'locality');
    if (locality) return locality;
    if (rowAdresse) {
      return rowAdresse.replace(/^[^,]+,([^,]+),.*/, '$1');
    }
    if (!rowAdresse) {
      if (helperLocality) {
        return helperLocality;
      }
      if (!helperLocality) {
        if (helperAddress) {
          return helperAddress.replace(/^[^,]+,([^,]+),.*/, '$1');
        }
      }
    }
    return '';
  };

  render() {
    const { columns, pageSize, currentPage, expandedRowIds, hiddenColumnNames } = this.state;
    const { leads, totalLeads, classes, t, setSort } = this.props;
    const getLeadId = lead => lead.id;
    const DetailRowComponent = ({ row, ...restProps }) => {
      const onClick = () => {
        this.expandRow(row.id);
      };
      return (
        <TableRowDetail.Row {...restProps} style={{ cursor: 'pointer' }} hover onClick={onClick} />
      );
    };
    const HelperCell = ({ row, ...restProps }) => {
      const avatarUrl = _get(row, 'helper.avatar_url');
      return (
        <>
          <Table.Cell {...restProps}>
            {row.ask_for === ASK_FOR.OTHER && (row?.helped_first_name || row?.helped_full_name) && (
              <Badge
                overlap="circular"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                badgeContent={
                  <WelloAvatar
                    avatarUrl={row?.helped?.avatar_url}
                    firstName={row.helped_first_name || row.helped_full_name}
                    lastName={row.helped_last_name || ''}
                    backgroundColor={row?.helped?.avatar_background_color}
                    size={40}
                    avatarStyle={{ height: 20, width: 20, fontSize: '0.72rem' }}
                  />
                }
              >
                <WelloAvatar
                  avatarUrl={avatarUrl}
                  firstName={row?.full_name || row?.helper_full_name || row?.helper_first_name}
                  lastName={row?.helper_last_name}
                  backgroundColor={row.helper?.avatar_background_color}
                  size={36}
                />
              </Badge>
            )}
            {row.ask_for !== ASK_FOR.OTHER &&
              (row?.full_name || row?.helper_full_name || row?.helper_first_name) && (
                <WelloAvatar
                  avatarUrl={avatarUrl}
                  firstName={row?.full_name || row?.helper_full_name || row?.helper_first_name}
                  lastName={row?.helper_last_name}
                  backgroundColor={row.helper?.avatar_background_color}
                  size={36}
                />
              )}
          </Table.Cell>
        </>
      );
    };

    const CityCell = ({ row, ...restProps }) => {
      return (
        <Table.Cell row={row} {...restProps}>
          {this.getCity(row)}
        </Table.Cell>
      );
    };

    const TypeCell = ({ value, row, ...restProps }) => {
      if (row.service) {
        return (
          <>
            <Table.Cell key={row.id} {...restProps}>
              <Chip
                key={`${row.id}-${row.service.id}`}
                className={classes.chip}
                label={row.service.label}
                style={{
                  backgroundColor: getExpertiseStyle(row.service.expertise).color,
                }}
              />
            </Table.Cell>
          </>
        );
      }
      return (
        <Table.Cell row={row} value={value} {...restProps}>
          <Chip
            className={classes.chip}
            label={t(`APP.LEADS.${row.type}`)}
            style={{
              backgroundColor: isSharedLead(row) ? grey[700] : grey[500],
              color: 'white',
            }}
          />
        </Table.Cell>
      );
    };
    const DateCell = ({ value, ...restProps }) => {
      const dateCreate = value;

      if (null === dateCreate) {
        return <Table.Cell {...restProps}></Table.Cell>;
      }

      return <Table.Cell {...restProps}>{moment(dateCreate).format('L')}</Table.Cell>;
    };
    const ActionCell = ({ row, ...restProps }) => {
      return (
        <>
          <Table.Cell {...restProps} key={row.id}>
            {row.responsible && row.validated && <IconCheck className={classes.checkIcon} />}
            {row.responsible && !row.validated && (
              <QuestionMarkIcon fontSize="small" className={classes.questionIcon} />
            )}
            {!row.responsible && <CloseIcon className={classes.crossIcon} />}
          </Table.Cell>
        </>
      );
    };
    const Cell = state => {
      const { column } = state;
      switch (column.name) {
        case 'type':
          return <TypeCell {...state} />;
        case 'ville':
          return <CityCell {...state} />;
        case 'actions':
          return <ActionCell {...state} />;
        case 'demandeur':
          return <HelperCell {...state} />;
        case 'datedemande':
          return (
            <DateCell {...state} value={state.row.created_at ? state.row.created_at : undefined} />
          );
        case 'departure_date':
          return <DateCell {...state} value={state.row.meta?.departure_date || null} />;
        default:
          return <Table.Cell {...state} />;
      }
    };

    const RowDetail = ({ row }) => {
      const { canArchive, generateShowLeadLink, gridMode, currentUser } = this.props;

      return (
        <div className={classes.detail}>
          {row.description && (
            <GridMui className={classes.infoLine}>
              <IconMessage />
              <Typography variant="body1" className={classes.infoText}>
                {row.description}
              </Typography>
            </GridMui>
          )}
          {row.ask_for !== ASK_FOR.ME && row.helped_full_name && (
            <GridMui className={classes.infoLine}>
              <IconPerson />
              <Typography
                variant="body1"
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: t('APP.LEADS.FIELDS.HELPED_FULL_NAME', {
                    helpedFullName: `<b>${row.helped_full_name}</b>`,
                  }),
                }}
              />
            </GridMui>
          )}
          {row.ask_for === ASK_FOR.ME && (row.full_name || row.helper_first_name) && (
            <GridMui className={classes.infoLine}>
              <IconPerson />
              <Typography
                variant="body1"
                className={classes.infoText}
                dangerouslySetInnerHTML={{
                  __html: t('APP.LEADS.FIELDS.HELPED_FULL_NAME', {
                    helpedFullName: row.full_name
                      ? `<b>${row.full_name}</b>`
                      : `<b>${row.helper_first_name} ${row.helper_last_name[0]}.</b>`,
                  }),
                }}
              />
            </GridMui>
          )}
          {row.recurrence && (
            <GridMui className={classes.infoLine}>
              <IconDateRange />
              <Typography variant="body1" className={classes.infoText}>
                {row.recurrence}
              </Typography>
            </GridMui>
          )}
          {row.meta && row.meta.departure_date && (
            <GridMui className={classes.infoLine}>
              <IconDateRange />
              <Typography variant="body1" className={classes.infoText}>
                {moment(row.meta.departure_date).format('LLL')}
              </Typography>
            </GridMui>
          )}
          {row.responsible && (
            <GridMui style={{ display: 'flex', alignItems: 'center' }}>
              {row.validated ? <IconCheck /> : <QuestionMarkIcon fontSize="small" />}
              <Typography variant="body1" className={classes.infoText}>
                {row.validated
                  ? t('APP.LEADS.STATE.RESPONSIBLE_VALIDATED', {
                      firstName: row?.responsible?.fullname || row.responsible.first_name,
                    })
                  : t('APP.LEADS.STATE.RESPONSIBLE_PENDING', {
                      firstName: row?.responsible?.fullname || row.responsible.first_name,
                    })}
              </Typography>
            </GridMui>
          )}
          <div
            className="row"
            style={{ marginTop: '30px', display: 'flex', justifyContent: 'flex-end' }}
          >
            {gridMode !== 'Family' && !row.responsible_id && !row.archived && (
              <Button
                style={{ marginRight: '20px' }}
                size="small"
                onClick={event => this.handleProposeMyselfMenuItemClick(row, event)}
              >
                {row?.service?.performed_by === 'volunteer'
                  ? t('APP.LEADS.ACTION.PROPOSE_MYSELF')
                  : t('APP.LEADS.ACTION.CONFIRM')}
              </Button>
            )}
            {!row.archived && canArchive(row) && (
              <Button
                style={{ marginRight: '20px' }}
                size="small"
                onClick={event => this.handleToggleArchiveMenuItemClick(row, event)}
              >
                {t('APP.LEADS.ACTION.ARCHIVE')}
              </Button>
            )}
            <Button
              size="small"
              // onClick={event => this.handleClick(row, event)}
              component={Link}
              to={generateShowLeadLink(row)}
            >
              {t('CONSULT')}
            </Button>
            {row?.service?.editable &&
              CheckRoleRule(
                getRoleInOrga(currentUser, row.organisation.holding_slug, row.organisation.slug),
                'leads:edit',
                { userId: currentUser?.id, leadOwnerId: row?.helper?.id, lead: row },
              ) && (
                <Button
                  size="small"
                  component={Link}
                  to={`/${row.organisation.holding_slug}/${row.organisation.slug}/quotation/edit/${row.id}`}
                >
                  {t('APP.LEADS.ACTION.EDIT')}
                </Button>
              )}
          </div>
        </div>
      );
    };

    return (
      <Grid rows={leads} columns={columns} getRowId={getLeadId}>
        <RowDetailState
          expandedRowIds={expandedRowIds}
          onExpandedRowIdsChange={this.setExpandedRowIds}
        />
        <SortingState
          sorting={this.state.sorting}
          onSortingChange={sorting => {
            this.setState({ sorting });

            if (!setSort) {
              return;
            }

            let column = null;
            switch (sorting[0]?.columnName) {
              case 'demandeur':
                column = 'full_name';
                break;
              case 'datedemande':
                column = 'created_at';
                break;
              case 'organisation':
                column = 'organisation';
                break;
              case 'ville':
                column = 'locality';
                break;
              case 'type':
                column = 'service';
                break;
              case 'departure_date':
                column = 'departure_date';
                break;
              case 'actions':
                column = 'responsible';
                break;
            }

            setSort(column, sorting[0]?.direction || 'ASC');
          }}
          columnExtensions={[
            {
              columnName: 'commentaire',
              sortingEnabled: false,
            },
          ]}
        />
        <PagingState
          pageSize={pageSize}
          currentPage={currentPage}
          onCurrentPageChange={this.setCurrentPage}
        />
        {!setSort && <IntegratedSorting />}
        <CustomPaging totalCount={totalLeads} />
        <Table
          columnExtensions={[
            {
              columnName: 'organisation',
              width: window.innerWidth > 600 ? '15%' : '25%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'demandeur',
              width: window.innerWidth > 600 ? '15%' : '20%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'ville',
              width: window.innerWidth > 600 ? '15%' : '25%',
              wordWrapEnabled: true,
              hidingPriority: 2,
            },
            {
              columnName: 'type',
              width: checkHostConstraint(HOSTS_NAMES.mormal) ? '20%' : '25%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'commentaire',
              width: checkHostConstraint(HOSTS_NAMES.mormal) ? '8%' : '10%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'datedemande',
              width:
                window.innerWidth > 600
                  ? checkHostConstraint(HOSTS_NAMES.mormal)
                    ? '12%'
                    : '20%'
                  : '40%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'departure_date',
              width:
                window.innerWidth > 600
                  ? checkHostConstraint(HOSTS_NAMES.mormal)
                    ? '12%'
                    : '20%'
                  : '40%',
              wordWrapEnabled: true,
            },
            {
              columnName: 'actions',
              width: '10%',
              wordWrapEnabled: true,
            },
          ]}
          cellComponent={Cell}
          rowComponent={DetailRowComponent}
        />
        <TableColumnVisibility hiddenColumnNames={hiddenColumnNames} />
        <TableHeaderRow showSortingControls align />
        <TableRowDetail contentComponent={RowDetail} />
        <PagingPanel />
      </Grid>
    );
  }
}

export default withStyles(styles)(withRouter(withTranslation()(GridLeads)));
