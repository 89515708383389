import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import HomeIcon from '@material-ui/icons/Home';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import EventIcon from '@material-ui/icons/Event';
import LocalTaxiIcon from '@material-ui/icons/LocalTaxi';
import SchoolIcon from '@mui/icons-material/School';

import { CheckOrgaRule } from '../../../Acl/Rules';
import { RoleCheck } from '../../Shared/Can';
import {
  getRole,
  hasCarenotebooks,
  isFamilyPro,
  isMainHelper,
  isMyFamily,
} from '../../../Acl/Controls';
import MenuItemCarenotebooks from './MenuItemCarenotebooks';
import CardFamilyHome from '../../Families/CardFamilyHome';
// import UserSettings from '../../../Pages/UserSetup/UserSettings';
import OpenCareNotebook from '../../CareNotebook/OpenCareNotebook';
import UserActions from '../../../Redux/UsersRedux';
import { checkHostConstraint, HOSTS_NAMES } from '../../../Routing/HostConstraint';

type Props = {
  organisation: Object,
  family: Object,
};

const useStyles = makeStyles(theme => ({
  bold: {
    fontWeight: '700',
  },
  title: {
    padding: theme.spacing(2),
  },
  description: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
  padding: {
    paddingLeft: theme.spacing(1),
    width: '100%',
  },
  menuList: {
    paddingTop: 0,
    paddingBottom: 0,
  },
  buttonLink: {
    textTransform: 'none',
    '&:hover': {
      backgroundColor: 'transparent',
      textDecoration: 'underline',
    },
  },
  openCarenotebook: {
    padding: theme.spacing(1),
    margin: theme.spacing(2, 0),
  },
  paper: {
    padding: theme.spacing(4),
  },
  marginBottom: {
    marginBottom: theme.spacing(2),
  },
  listItem: {
    marginRight: '-15px',
    color: 'inherit',
  },
}));

const Menu = ({ organisation, family }: Props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const queryParam = new URLSearchParams(location.search);
  const urlParams = {
    dialog: queryParam.get('dialog') || '',
  };
  const [shownUser, setShownUser] = React.useState(null);
  const match = useRouteMatch();
  const { family_slug: familySlug } = match.params;
  const members = useSelector(state => state.organisationMembership.members);
  const usersOrganisations = useSelector(state => state.users.usersOrganisations);
  const newFamily = useSelector(state => state.users.userUpdated);
  const [showOpenCareNotebook, setShowOpenCareNotebook] = React.useState(false);
  // const [showHelpedSettings, setShowHelpedSettings] = React.useState(false);
  const [showCarenotebookIsOpen, setShowCarenotebookIsOpen] = React.useState(false);
  const [isCareNotebookForMe, setIsCareNotebookForMe] = React.useState(false);
  const hasHelpeds = family?.admin?.families?.length > 1;
  const isPartOfOrganisations = family?.admin?.organisations?.length > 0;
  const currentUser = useSelector(state => state.auth.currentUser);
  const role = getRole(currentUser, organisation?.holding_slug, organisation?.slug, familySlug);
  const currentUserIsFamilyProOrMainHelper = isFamilyPro(role) || isMainHelper(role);
  const firstConnexionDialog = urlParams.dialog === 'carenotebook';
  const isVolunteer = usersOrganisations[shownUser]?.some(
    organisation => organisation.role === 'volunteer',
  );

  const canOpenCarenoteBook =
    !hasCarenotebooks(family) &&
    (isPartOfOrganisations || hasHelpeds) &&
    (organisation
      ? CheckOrgaRule(organisation?.type, 'open-carenotebook')
      : CheckOrgaRule(family?.type, 'open-carenotebook'));

  React.useEffect(() => {
    if (!members) {
      return;
    }
    setShownUser(members[0].helper_id);
    dispatch(UserActions.getUserOrganisationsRequest(members[0].helper_id));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [members]);

  React.useEffect(() => {
    if (firstConnexionDialog && !family?.admin?.has_carenotebook && !hasHelpeds)
      setShowOpenCareNotebook(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [firstConnexionDialog]);

  const handleSubmit = targetCareNotebook => {
    if (targetCareNotebook === 'forMe') {
      dispatch(
        UserActions.updateUserRequest(
          family.admin.id,
          {
            has_carenotebook: true,
          },
          currentUser.avatar && currentUser.avatar.size > 0 ? currentUser.avatar : null,
        ),
      );
      setShowOpenCareNotebook(false);
      setShowCarenotebookIsOpen(true);
      // } else if (targetCareNotebook === 'forMyHelped') {
      //   setShowHelpedSettings(true);
    } else {
      setShowOpenCareNotebook(false);
      history.push(`/families/${family?.slug}`);
    }
    setIsCareNotebookForMe(targetCareNotebook === 'forMe');
  };

  // const onCloseUserSettings = () => {
  //   setShowHelpedSettings(false);
  //   setShowOpenCareNotebook(false);
  //   setShowCarenotebookIsOpen(true);
  // };

  const handleValidate = () => {
    if (isCareNotebookForMe) setShowCarenotebookIsOpen(false);
    else history.push(`/families/${newFamily?.slug}`);
  };

  const onCloseCareNotebookDialog = () => {
    setShowOpenCareNotebook(false);
    history.push(`/families/${family?.slug}`);
  };

  // Family home menu
  return (
    <>
      <Paper>
        <CardFamilyHome family={family} canSeeIdentifier={currentUserIsFamilyProOrMainHelper} />
        <MenuList className={classes.menuList}>
          <MenuItem className={classes.bold} component={Link} to={`/families/${family.slug}`}>
            <ListItemIcon className={classes.listItem}>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText>{t('APP.ORGANISATION.MENU.USER_HOME')}</ListItemText>
          </MenuItem>
          {!organisation && (
            <RoleCheck
              role={family.role}
              action="leads:read"
              yes={() => (
                <MenuItem
                  component={Link}
                  to={`/families/${family.slug}/leads`}
                  className={classes.bold}
                >
                  <ListItemIcon className={classes.listItem}>
                    <ShoppingBasketIcon />
                  </ListItemIcon>
                  <ListItemText className={classes.listItem}>
                    {t('APP.ORGANISATION.MENU.FAMILY_HOME_LINK', {
                      context: isMyFamily(family) && 'MINE',
                    })}
                  </ListItemText>
                </MenuItem>
              )}
            />
          )}
          <MenuItem
            className={classes.bold}
            component={Link}
            to={`/families/${family.slug}/schedule`}
          >
            <ListItemIcon className={classes.listItem}>
              <EventIcon />
            </ListItemIcon>
            <ListItemText>
              {t('APP.ORGANISATION.MENU.SCHEDULE_LINK', {
                context: isMyFamily(family) && 'MINE',
              })}
            </ListItemText>
          </MenuItem>
          {isVolunteer && checkHostConstraint(HOSTS_NAMES.mormal) && (
            <MenuItem
              className={classes.bold}
              component={Link}
              to={`/families/${family.slug}/transport`}
            >
              <ListItemIcon className={classes.listItem}>
                <LocalTaxiIcon />
              </ListItemIcon>
              <ListItemText>{t('APP.ORGANISATION.MENU.MY_TRANSPORT')}</ListItemText>
            </MenuItem>
          )}
          {((isMyFamily(family) && currentUser?.has_courses) || !isMyFamily(family)) && (
            <RoleCheck
              role={family.role}
              action="courses:read"
              yes={() => (
                <MenuItem
                  className={classes.bold}
                  component={Link}
                  to={`/families/${family.slug}/formations`}
                >
                  <ListItemIcon className={classes.listItem}>
                    <SchoolIcon />
                  </ListItemIcon>
                  <ListItemText>
                    {t('APP.ORGANISATION.MENU.COURSE_LINK', {
                      context: isMyFamily(family) && 'MINE',
                    })}
                  </ListItemText>
                </MenuItem>
              )}
            />
          )}
          {hasCarenotebooks(family) && <MenuItemCarenotebooks family={family} hideAccordion />}
        </MenuList>
      </Paper>
      {canOpenCarenoteBook && (
        <Paper className={classes.openCarenotebook}>
          <Button
            className={classes.buttonLink}
            color="primary"
            onClick={() => setShowOpenCareNotebook(true)}
          >
            {t('OPEN_CARENOTEBOOK')}
          </Button>
        </Paper>
      )}
      <Dialog open={showOpenCareNotebook} onClose={onCloseCareNotebookDialog} maxWidth="md">
        <OpenCareNotebook
          handleSubmit={handleSubmit}
          onClose={onCloseCareNotebookDialog}
          // isFirstConnexion={urlParams.dialog === 'carenotebook'}
          currentUserIsProOrMainHelper={currentUserIsFamilyProOrMainHelper}
        />
      </Dialog>
      {/* <Dialog open={showHelpedSettings} onClose={() => setShowHelpedSettings(false)} maxWidth="md">
        <UserSettings
          dialogTitle={t(hasHelpeds ? 'OPEN_CARENOTEBOOK_FOR_FAMILY' : '')}
          onClose={onCloseUserSettings}
          onCancel={() => setShowHelpedSettings(false)}
          helpedMainHelperId={family?.admin?.id}
        />
      </Dialog> */}
      <Dialog
        open={showCarenotebookIsOpen}
        onClose={() => setShowCarenotebookIsOpen(false)}
        fullWidth
      >
        <Paper className={classes.paper}>
          <Typography align="center" component="h2" variant="h5" className={classes.marginBottom}>
            {t('APP.CARENOTEBOOK.IS_OPEN')}
          </Typography>
          <Typography variant="h6" component="p" className={classes.marginBottom}>
            {t('APP.CARENOTEBOOK.IS_OPEN.SUBTITLE', {
              context: !isCareNotebookForMe && 'HELPER',
            })}
          </Typography>
          <Typography variant="subtitle2" component="p" gutterBottom>
            {t('APP.CARENOTEBOOK.IS_OPEN.BODY_HEALTHCARE')}
          </Typography>
          <Typography variant="subtitle2" component="p" gutterBottom>
            {t('APP.CARENOTEBOOK.IS_OPEN.BODY_DAILYLIFE')}
          </Typography>
          <Grid container style={{ marginTop: 16, justifyContent: 'flex-end' }}>
            <Button className={classes.buttonLink} color="primary" onClick={handleValidate}>
              {t(isCareNotebookForMe ? 'OK' : 'APP.CARENOTEBOOK.IS_OPEN.GO_FAMILY')}
            </Button>
          </Grid>
        </Paper>
      </Dialog>
    </>
  );
};

export default Menu;
